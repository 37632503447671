import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
// 引用fullpage 插件
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import "../src/static/fullpage.min.css"; // Optional. When using fullpage extensions
import VueFullPage from "vue-fullpage.js";

Vue.use(VueFullPage);
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.use(ElementUI);
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    setTimeout(() => {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", to.meta.content.keywords);
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", to.meta.content.description);
      meta.content = to.meta.content;

      head[0].appendChild(meta);
    }, 1000);
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
