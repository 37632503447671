import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 公司介绍页
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/index"),
    meta: {
      title: "宁波蓝犀信息科技有限公司",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  // 解决方案页
  {
    path: "/solution_retail",
    name: "Solution_retail",
    component: () => import("@/views/solution/solution_retail"),
    meta: {
      title: "智能新零售解决方案",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  // 合作案例页
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("@/views/cooperation"),
    meta: {
      title: "园区案例",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  //  园企行
  {
    path: "/cooperation/garden",
    name: "Garden",
    component: () => import("@/views/garden"),
    meta: {
      title: "园企行案例",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  // 新闻页
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/news"),
    meta: {
      title: "企业新闻",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  // app介绍
  {
    path: "/app",
    name: "App",
    component: () => import("@/views/app"),
    meta: {
      title: "i招犀",
      content: {
        keywords: "i招犀、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  },
  {
    path: "/news/news_detail/:id",
    name: "News_item6",
    component: () => import("@/views/news/news_item"),
    meta: {
      title: "企业新闻详情",
      content: {
        keywords:
          "宁波蓝犀信息科技有限公司、蓝犀、蓝犀智创、蓝犀科技、黑科技、高科技、IT",
        description:
          "宁波蓝犀信息科技有限公司从成立初期便致力于通过智能化手段解决传统线下业务场景信息不对称问题，如今已成为集物联网、人工智能、数据分析等为一体的解决方案供应商。公司核心团队拥有多年研发经验，以技术为驱动，以产品思维为导向，以客户为中心，通过创新的的产品为客户创造价值。"
      }
    }
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
});
export default router;
